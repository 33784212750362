<template>
  <div class="d-flex justify-content-center full-height full-width align-items-center">
    <div class="error-container text-center">
      <!--      <h1 class="error-number">404</h1>-->
      <h2 class="semi-bold">{{ $t("auth.url invalid") }}</h2> <br/><br/><br/><br/>
<!--      <router-link :to="{ name: ROUTES.ADMIN.ALL_USERS_LIST }" style="text-decoration: underline">管理画面へ</router-link>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "VerifyFalse",
  data() {
    return {
      msg: ""
    }
  },
  mounted: function () {
    this.$request.get(this.ENDPOINT.VERIFY_EMAIL(this.$route.query.id,this.$route.query.token)).then(res => {
      if (res.hasErrors()) {
        this.msg = this.$t("auth.url invalid");
      } else {
        this.msg = res.data.msg;
      }
    })
  },
}
</script>
